

















































































































.hero-background {
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
}
